@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&family=Montserrat+Alternates:wght@600&family=Noto+Sans+TC:wght@100;300;500&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
  margin: 0px;
  padding: 0px;
  user-select: none;
  font-family: "Inter", "Noto Sans TC", sans-serif;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  background-color: rgb(252, 252, 252);

  /* background-color: aqua;
  border: 30px solid white; */
}
