/* 開發用 */

.border {
  outline: 1px solid red;
}

/* 基本設定 */

.p-end {
  margin-bottom: 50px;
}

/* .placeholder {
  height: 100px;
} */

.contentimg {
  width: 100%;
  height: auto;
  margin: 50px 0px 25px 0px;
}

.contentimg-fluid {
  width: 100vw;
  height: auto;
  margin: 50px 0px 25px 0px;
}
.contentimg-intro {
  width: 100%;
  height: auto;
  margin: 10px 0px 0px 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.highlighttext {
  font-weight: 400;
  background-image: linear-gradient(to bottom, #02c3994f, #02c39983);
  /* background-image: linear-gradient(to bottom, #4545454f, #45454583); */
  background-size: auto 6px;
  background-repeat: no-repeat;
  background-position: bottom;
}

/* .highlighttext:hover {
  background-size: auto 10px;
  transition: 0.2s ease-in-out;
} */

/* .highlighttext::after {
  content: "";

  position: absolute;
  width: 100%;
  height: 5px;
  bottom: 0;
  left: 0;

  background-color: #b698ee9c;
  z-index: -100000;
} */

.caption {
  font-family: "Inter", "Noto Sans TC", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: gray;
  margin: 0px 0px 50px 0px;
}

.notitle {
  margin-top: 30px;
}

ol {
  max-width: 600px;
}

li {
  max-width: 550px;
  font-size: 18px;
  font-weight: 100;
  font-family: "Inter", "Noto Sans TC", sans-serif;
  line-height: 32px;
  margin-bottom: 15px;
}

h4 {
  font-size: 18px;
  font-family: "Inter", "Noto Sans TC", sans-serif;
  text-align: left;
  color: #636363;
  font-weight: 500;
  margin: 40px 0px 0px 0px;
}

h3 {
  font-size: 22px;
  font-family: "Inter", "Noto Sans TC", sans-serif;
  color: #454545;
  font-weight: 500;
}

h1 {
  font-size: 30px;
  font-family: "Inter", "Noto Sans TC", sans-serif;
  color: #454545;
  font-weight: 500;
  /* letter-spacing: 2px;
  font-weight: 400; */
}

p {
  font-size: 18px;
  max-width: 600px;
  font-family: "Inter", "Noto Sans TC", sans-serif;
  font-weight: 100;
  color: #454545;
  margin: 10px 0px;
  line-height: 32px;
}

.text-center {
  text-align: center;
}

.container {
  box-sizing: border-box;
  max-width: 800px;
  height: auto;
  margin: 30px 0px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
}

.container-fluid {
  box-sizing: border-box;
  width: 100vw;
  height: auto;
  margin: 30px 0px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
}

.container-intro {
  box-sizing: border-box;
  max-width: 800px;
  height: auto;
  margin: 30px 0px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
}

.decoration-none {
  text-decoration: none;
}

.display-none {
  display: none;
}

.paddingRight-10 {
  padding-right: 10px 0px;
}

.font {
  font-size: 30px;
}

.fixed {
  position: fixed;
}

.maxWidth-800 {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.height-1000 {
  height: 1000px;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.flex-row-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: left;
}

.flex-column-reverse_center {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

/* 導覽列 */

.header {
  top: 0px;
  left: 0px;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 100000;
}

.nav {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  font-family: "Inter", "Noto Sans TC", sans-serif;
  width: inherit;
  height: 120px;
  max-width: 800px;
  transition: 300ms;
  background-color: white;
  z-index: 1000;
}

.navscroll {
  height: 70px;
  transition: 300ms;
}

.onscroll {
  box-shadow: 2px 2px 8px -1px rgba(59, 59, 59, 0.2);
  transition: 300ms;
}

.mobileNavIcon {
}

.mobileNavul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobileNavMenu {
  background-color: #454545;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 100000;
}

.navList {
}

.navListItem {
  text-decoration: none;
  font-weight: 300;
  color: #454545;
  padding: 10px 15px;
  margin: 0px 5px;
  background-color: rgb(255, 255, 255);
  transition: 300ms;
  text-decoration: none;
}

.mobileNavListItem {
  font-size: 18px;
  text-decoration: none;
  font-weight: 300;
  color: white;
  padding: 20px 40px;
  margin: 0px 5px;
  transition: 300ms;
  text-decoration: none;
}

.navListItem:hover {
  background-color: rgb(244, 244, 244);
  transition: 300ms;
  border-radius: 4px;
  color: #101010;
  text-decoration: none;
}

.identity {
  font-family: "Montserrat Alternates", sans-serif;
  font-size: 20px;
  padding-right: 10px;
  color: #454545;
  text-decoration: none;
}

.identity2 {
  font-family: "Inter", "Noto Sans TC", sans-serif;
  font-weight: 200;
  color: #454545;
  text-decoration: none;
}

/* 自我介紹 */

.intro {
  padding: 130px 0px 0px 0px;
}

.IntroImgContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.intro-h1 {
  font-size: 45px;
  font-family: "Inter", "Noto Sans TC", sans-serif;
  font-weight: 500;
  height: 60px;
  color: #454545;
  padding-bottom: 0px;
  margin-bottom: 15px;
  white-space: nowrap;
}

.intro-p {
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  width: 100%;
  max-width: 500px;
  font-family: "Inter", "Noto Sans TC", sans-serif;
  font-weight: 200;
  color: #454545;
}

.introImg {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 300px;
  padding-left: 0px;
  height: auto;
  /* outline: 1px solid rebeccapurple; */
}

.skillcontainer {
  display: flexbox;
  align-items: center;
  justify-content: flex-start;
}

.skill {
  height: 50px;
  box-sizing: border-box;
  /* width: 200px; */

  border-radius: 4px;
  margin: 10px 20px;
}

.pattern1 {
  background-image: repeating-linear-gradient(
    45deg,
    #05668d 0,
    #05668d 10px,
    transparent 0,
    transparent 50%
  );
  background-size: 15px 15px;
  background-color: #05668d4c;
  border: 1.5px solid #05668d;
}

.pattern1:hover {
  background-color: #05668d;
  transition: 500ms;
}

.pattern2 {
  background-image: repeating-linear-gradient(
    45deg,
    #028090 0,
    #028090 10px,
    transparent 0,
    transparent 50%
  );
  background-size: 15px 15px;
  background-color: #02809039;
  border: 1.5px solid #028090;
}

.pattern2:hover {
  background-color: #028090;
  transition: 300ms;
}

.pattern3 {
  background-image: repeating-linear-gradient(
    45deg,
    #02c39a 0,
    #02c39a 10px,
    transparent 0,
    transparent 50%
  );
  background-size: 15px 15px;
  background-color: #02c39a29;
  border: 1.5px solid #02c39a;
}

.pattern3:hover {
  background-color: #02c39a;
  transition: 300ms;
}

.skillcontainer p {
  font-family: "Inter", "Noto Sans TC", sans-serif;
  font-weight: 200;
  /* color: #ffdc05; */
  white-space: nowrap;
}

/* .skill:hover {
  background-image: url();
  background-color: #454545;
  border: 1px solid #454545;
  transition: 500ms;
} */

.personalities {
}

.habbits {
}

.mybtn {
  font-family: "Inter", "Noto Sans TC", sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  text-align: center;
  line-height: 50px;
  width: 210px;
  height: 50px;
  background-color: #454545;
  color: white;
  border-radius: 4px;
  margin-top: 50px;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.mybtn:disabled {
  background-color: #d3d3d3;
}

.mybtn a {
  text-decoration: none;
  color: white;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.mybtn p {
  font-family: "Inter", "Noto Sans TC", sans-serif;
  color: white;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.mybtn.disable {
  background-color: #c3c3c3;
  color: rgb(134, 134, 134);
}

/* .mybtn:hover {
  background-color: #783434;
  color: white;
  transition: 300ms;
} */

/* 施工中圖示 */

.container-inprogress {
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.inprogress-box {
  display: flex;
  width: 300px;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inprogress-img {
  width: 200px;
}

.inprogress-text {
  font-family: "Inter", "Noto Sans TC", sans-serif;
  font-size: 18px;
  font-weight: 300;
}

/* footer */

.footer {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
  bottom: 0;
  left: 0;
  width: 100vw;
  border-top: 1px solid #d3d3d3;
  margin: 0px;
  padding: 50px 16px;

  /* background-color: #454545; */
}

.footer h3 {
  font-family: "Inter", "Noto Sans TC", sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin: 20px 0 10 0;
  color: #454545;
}

.footer-p {
  font-family: "Inter", "Noto Sans TC", sans-serif;
  font-size: 16px;
  margin: 0px;
  text-align: center;
  line-height: 30px;
  font-weight: 300;

  color: #454545;
  margin-bottom: 5px;
  user-select: all;
}

.footer-note {
  font-family: "Inter", "Noto Sans TC", sans-serif;
  font-size: 14px;
  margin: 0px;
  text-align: center;
  line-height: 30px;
  font-weight: 300;
  max-width: 400px;
  color: #b1b1b1;
  margin: 15px 0px;
}

.socialproof {
  margin: 100px 0px;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.socialdecoline {
  width: 1.3px;
  height: 50px;
  background-color: #454545;
  margin: 5px 0px;
}

.socialdecocircle {
  width: 6px;
  height: 6px;
  background-color: #454545;
}

.socialproof p {
  font-family: "Inter", "Noto Sans TC", sans-serif;
  text-align: center;
  font-size: 14px;
  font-weight: 200;
  color: #777777;
  margin-bottom: 50px;
}

.projectList {
}

.projectItemContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  width: 100%;
  height: 400px;
  border-radius: 4px;
  background-color: #f0f0f0;
  margin: 0px 0px 30px 0px;
  text-decoration: none;
  padding: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.project1Img {
  background-image: url(./assets/project1Img.jpg);
  background-position: center;
  /* background-size: cover; */
  background-repeat: no-repeat;
}

.project2Img {
  background-image: url(./assets/project2Img.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.project3Img {
  background-image: url(./assets/project3Img.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.projectItemContainerText {
  box-sizing: border-box;
  padding-left: 10px;
  width: 50%;
  /* outline: 1px solid red; */
}

.projectItemContainerImg {
  max-width: 50%;
  width: 50%;
  /* outline: 1px solid red; */
}

.projectItemContainer h3 {
  font-size: 24px;
  max-width: 300px;
  font-family: "Inter", "Noto Sans TC", sans-serif;
  font-weight: 500;
  margin: 20px 0px 0px 0px;
  color: #454545;
}

.projectItemContainer p {
  font-size: 16px;
  max-width: 300px;
  font-family: "Inter", "Noto Sans TC", sans-serif;
  font-weight: 300;
  color: #454545;
  line-height: 22px;
}

.projectItemContainer h4 {
  font-family: "Inter", "Noto Sans TC", sans-serif;
  font-size: 12px;
  font-weight: 700;
  margin: 5px 0px;
  color: #497f37;
}

.projectKV01 {
  border-radius: 5px;
  box-sizing: border-box;
  max-width: 800px;
  height: auto;
  margin: 30px 0px;
  padding: 40px 16px 40px 16px;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(83.05deg, #3a3a3a 15.8%, #2f2d2d 87.22%);
  /* background-image: linear-gradient(0deg, #ffdee9 0%, #b5fffc 100%); */

  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* border: 2px solid #454545; */
}

.projectKVtext {
  height: 40%;
  width: 100%;
}
.projectKVimg {
  height: 60%;
  width: 100%;
  margin-top: 70px;
}

.projectImg {
  width: 70%;
  height: auto;
}

.projectKV01 h1 {
  color: #f2f2f2;
  letter-spacing: 1px;
}

.projectKV01 p {
  font-size: 16px;
  color: #dadada;
}

.overview {
  /* background-color: #ffdee9; */
  border-radius: 5px;
  height: auto;
  padding: 16px;
}

.overview p {
  font-size: 16px;
}

.overviewcontent {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-self: space-around;
}

.overview div {
  margin: 0px 20px;
  height: auto;
  width: 100%;
}

.casetitle {
  text-align: center;
  margin: 100px 0px 30px 0px;
}
.casetitle span {
  font-size: 14px;
  font-family: "Inter", "Noto Sans TC", sans-serif;
  font-weight: 500;
  color: #02c39a;
}

.sectionend {
  width: 60px;
  height: 6px;
  background-color: #454545;
  margin: 50px 0px;
  border-radius: 1px;
}

.sectionstart {
  width: 30px;
  height: 3px;
  background-color: #454545;
  margin-bottom: 20px;
  border-radius: 1px;
}

/* .summary {
  border-radius: 4px;
  border: 1px solid #000000;
  background-color: #d6d6d6;
  position: relative;
  max-width: 600px;
  padding: 50px 50px;
  margin: 20px 0px 20px 0px;
}

.summary::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #141414b6;
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: -10000;
  border-radius: 4px;
} */

.summary {
  border-radius: 4px;
  border: 1px solid #24d9b1;
  background-color: #8dffe6;
  position: relative;
  max-width: 600px;
  padding: 50px 50px;
  margin: 20px 0px 20px 0px;
}

.summary::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #52ffd9;
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: -10000;
  border-radius: 4px;
}

/* .summary:hover::before {
  top: 20px;
  left: 20px;
  transition: 0.3s;
} */

.summary h4 {
  margin: 0px 0px 20px 0px;
  text-align: center;
}

.summary p {
  font-size: 16px;
}

.summary li {
  font-size: 16px;
}

.skip {
  color: #05668d;
  cursor: pointer;
  font-weight: 500;
}

.about {
  box-sizing: border-box;
  text-align: center;
  margin: 30px 0px 30px 0px;
  padding: 60px 60px;
  background-color: #f9f9f9;
  width: 100%;
  border-radius: 4px;
}

.aboutImg {
  width: 100%;
  height: auto;
}

.about span {
  font-size: 14px;
  font-family: "Inter", "Noto Sans TC", sans-serif;
  font-weight: 500;
  color: #02c39a;
}

.aboutContainerImg {
  width: 50%;
  padding-left: 30px;
  /* outline: 1px solid red; */
}

.about p {
  text-align: left;
  font-size: 16px;
}

.about ul {
  font-size: 16px;
  margin: 0px 16px;
  padding: 0px;
}

.about li {
  font-size: 16px;
  margin: 10px 0px;
}

.experiencetitle {
  width: auto;
  height: 100%;
  position: relative;
}

.experiencetitle h4 {
  margin: 0px;
}

.experiencetitle p {
  margin: 0px;
}

.experiencetitle::before {
  content: "";
  height: 100%;
  width: 6px;
  background-color: #02c39a;
  position: absolute;
  left: -15px;
  z-index: 10000;
}

/* -----------------------------------------------RWD設定-------------------------------------------------- */

@media screen and (max-width: 2880px) {
  .mobileNavIcon {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .navList {
    display: none;
  }

  /* .container {
    padding: 10px 16px;
  }*/

  .nav {
    height: 70px;
  }

  .IntroImgContainer {
    display: none;
  }

  .socialproof {
    display: none;
  }
  .projectItemContainer {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }
  .projectItemContainerText {
    width: 100%;
  }
  .projectItemContainerImg {
    width: 100%;
  }
  .aboutContainerImg {
    width: 100%;
    padding-left: 0px;
  }

  .projectItemContainer h3 {
    max-width: 200px;
  }
  .projectItemContainer p {
    max-width: 200px;
  }

  .project1Img {
    background-position: 40%;

    /* background-size: cover; */
  }
  .project2Img {
    background-position: 40%;

    /* background-size: cover; */
  }
  .project3Img {
    background-position: 40%;

    /* background-size: cover; */
  }

  .intro {
    padding: 100px 0px 0px 0px;
  }
  .mobileNavIcon {
    display: block;
  }

  .overviewcontent {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-self: center;
  }

  .overview div {
    margin: 0px 0px;
  }

  .summary {
    padding: 40px 20px;
  }
  .about {
    padding: 30px;
  }
  /* 
  .projectKV01 {
    height: 400px;
  } */
}
